import React from "react";

import Footer from "./Footer";
import "../App.css";
import Header from "./Header";

//scripts and files
import "../assets/css/bootstrap.min.css";
import "../assets/font-awesome/css/font-awesome.min.css";
import "../assets/css/lamunga.css";
import "../assets/css/style.css";

//images
import devices from "../assets/img/devices.jpg";
import networking from "../assets/img/networking.png";
import website from "../assets/img/website.png";
import cloudcomputing from "../assets/img/cloud-computing.png";
import cybersecurity from "../assets/img/cyber-security.png";
import businessapps from "../assets/img/businessapps.png";
import lamungamap from "../assets/img/lamungamap.png";
import helpdesk from "../assets/img/helpdesk1.jpg";

function Home() {
  const map_key =
    "https://www.google.com/maps/embed/v1/view?zoom=17&center=0.0074%2C37.0722&key=AIzaSyAdZUc6OLN8Nnj3_iLwKU7aGCNpKQj-3Hc";
  return (
    <div classNameName="Home-content">
      <Header />

      <div className="intro-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="intro-message">
                <br></br>
                <br></br>
                <br></br>
                <h1>Lamunga Technologies</h1>
                <h3>ICT Help Desk for Everyone</h3>
                <hr className="intro-divider"></hr>
                <ul className="list-inline intro-social-buttons">
                  <li>
                    <a href="#business-apps" className="btn btn-primary btn-lg">
                      <span className="network-name">Business Software</span>
                    </a>
                  </li>
                  <li>
                    <a href="#helpdesk" className="btn btn-primary btn-lg">
                      <span className="network-name">ICT Helpdesk</span>
                    </a>
                  </li>
                  <li>
                    <a href="#networking" className="btn btn-primary btn-lg">
                      <span className="network-name">Networking</span>
                    </a>
                  </li>
                  <li>
                    <a href="#website" className="btn btn-primary btn-lg">
                      <span className="network-name">Websites</span>
                    </a>
                  </li>
                  <li>
                    <a href="#devices" className="btn btn-primary btn-lg">
                      <span className="network-name">Devices</span>
                    </a>
                  </li>
                  <li>
                    <a href="#cloud" className="btn btn-primary btn-lg">
                      <span className="network-name">Cloud</span>
                    </a>
                  </li>

                  <hr></hr>
                </ul>
                <a href="/getintouch" className="btn btn-lg btn-danger">
                  Get Started with Lamunga
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a name="services"></a>
      <div className="content-section-a">
        <div className="container" id="business-apps">
          <div className="row">
            <div className="col-lg-5 col-sm-6">
              <div className="clearfix"></div>
              <a>
                <h2 className="section-heading">Business solutions</h2>
              </a>
              <h4 className="lead">
                We bring productivity in the work place with our wide range of
                business applications that cover all your needs.
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check"></i> Better communication with
                    business class Email
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Accounting with ERPs
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Customer relationship
                    management (CRM)
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> File storage and backup
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Payroll processing
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Team collaboration
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Point of Sale (POS)
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Call centre
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Business data security
                  </li>
                </ul>
                <a className="link" href="/shop">
                  Get business solutions
                </a>
              </h4>
            </div>
            <div className="col-lg-7 col-lg-offset-0 col-sm-6">
              <img
                className="img-responsive"
                src={businessapps}
                alt="Web Applications"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section-a">
        <div className="container" id="helpdesk">
          <div className="row">
            <div className="col-lg-5 col-lg-offset-0 col-sm-push-6  col-sm-6">
              <hr className="section-heading-spacer"></hr>
              <div className="clearfix"></div>
              <a>
                {" "}
                <h2 className="section-heading">ICT Helpdesk and Support</h2>
              </a>
              <p className="lead">
                We help you and your team to quickly resolve ICT issues and keep
                working on your business goals. When you choose us, you get 24/7
                non-stop IT support from qualified engineers proficient in
                technology and troubleshooting.
                <ul className="list-unstyled">
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Step-by-step troubleshooting
                    guide.{" "}
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Instant diagnosis and
                    resolution of issues.
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Setup and configuration of
                    devices
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i>Cloud services management
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i>Security patches and updates
                  </li>

                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Hardware repairs and
                    maintenance
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i>Detailed error and diagnosis
                    reports for accountability
                  </li>
                </ul>
                <a className="link" href="/helpdesk">
                  Get ICT Help and Support
                </a>
              </p>
            </div>
            <div className="col-lg-5 col-sm-pull-5  col-sm-6">
              <div className="clearfix"></div>
              <div className="clearfix"></div>
              <img className="img-responsive" src={helpdesk} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section-b">
        <div className="container" id="networking">
          <div className="row">
            <div className="col-lg-5 col-sm-6">
              <hr className="section-heading-spacer"></hr>
              <div className="clearfix"></div>
              <a>
                {" "}
                <h2 className="section-heading">Networking</h2>
              </a>
              <p className="lead">
                we help enterprises improve business agility, lower costs and
                reduce complexities to do with LAN and WAN connections. We also
                offer SD-WAN solutions to connect all your business branches.
                Our networking services include
                <ul className="list-unstyled">
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Network Setup and deployment
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Bandwidth optimization
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Network Access control
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Network Security
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Network cost analysis
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> Network devices supply
                  </li>
                  <li>
                    {" "}
                    <i className="fa fa-check"></i> WIFI connection
                  </li>
                </ul>
              </p>
              <a className="link" href="/services">
                Request network services
              </a>
            </div>
            <div className="col-lg-5 col-lg-offset-2 col-sm-6">
              <img className="img-responsive" src={networking} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="content-section-b" id="devices">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-lg-offset-1 col-sm-push-6  col-sm-6">
              <a>
                {" "}
                <h2 className="section-heading">Devices</h2>
              </a>
              <p className="lead">
                We have a wide range of computing devices and accessories from
                industry leading vendors such as HP, Lenovo, Dell, Sumsang,
                Apple, Sony, Microsoft, Google. These devices range from
                servers, laptops, desktops, screens, phones, storage routers,
                switches, radios, cameras, printers to IoT devices and
                accessories such as keyboards, mice and USB devices. You just
                have to name and we will supply it.
              </p>
              <a href="/shop">Browse devices in our shop</a>
            </div>
            <div className="col-lg-5 col-sm-pull-6  col-sm-6">
              <img className="img-responsive" src={devices} alt="Consultancy" />
            </div>
          </div>
        </div>

        <div className="content-section-a">
          <div className="container" id="website">
            <div className="row">
              <hr className="section-heading-spacer"></hr>
              <div className="clearfix"></div>
              <div className="col-lg-6 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Website and Domain</h2>
                </a>
                <p className="lead">
                  We create websites and web applications optimized for speed,
                  performance and search engine friendly with the best in class
                  web tools and frameworks such as react and host with reputable
                  cloud hosting providers to make your website visitors
                  comfortable enough to click the buy now button before leaving
                  the page. <br></br>
                  We include a complete package for web system and a mobile
                  application if request and of course at a reasonable pricing.
                  We also manage domains for our customers including renuwals,
                  SSL certificates, email forwarding and redirection of domain
                  requests to enterprise applications
                </p>
                <a className="link" href="/getintouch">
                  Ask for a website
                </a>
              </div>
              <div className="col-lg-4 col-lg-offset-2 col-sm-6">
                <img className="img-responsive" src={website} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="content-section-a" id="cloud">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-lg-offset-1 col-sm-push-6  col-sm-6">
                <hr className="section-heading-spacer"></hr>
                <div className="clearfix"></div>
                <a>
                  {" "}
                  <h2 className="section-heading">Cloud Services</h2>
                </a>
                <p className="lead">
                  We help businesses unlock their full potential with assisted
                  cloud migrations. You get unlimited storage, excellent
                  application and database performance, servers and almost any
                  application in the cloud of your choice. We step into your
                  business situation and guide you to make the best decision
                  your business needs.<br></br>
                  We do migrations for Microsoft Azure, Amazon Web Services and
                  Google cloud platform.
                </p>
                <a className="link" href="/services">
                  Ask for help with cloud services
                </a>
              </div>
              <div className="col-lg-5 col-sm-pull-6  col-sm-6">
                <img
                  className="img-responsive"
                  src={cloudcomputing}
                  alt="Cloud Services"
                />
              </div>
            </div>
            <br></br>
          </div>

          <div className="content-section-b">
            <div className="container" id="cyber-security">
              <div className="row">
                <div className="col-lg-5 col-lg-offset-0  col-sm-6">
                  <a>
                    {" "}
                    <h2 className="section-heading">Cyber Security</h2>
                  </a>
                  <p className="lead">
                    Secure your digital assets, your customers and employees
                    with our all inclusive cyber security products from industry
                    leading providers. We have cyber security applications for
                    your specific needs such as:
                    <ul className="list-unstyled">
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Network Firewalls{" "}
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Intrusion Detection and
                        prevention
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Network Access control
                        (NAC)
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Web Application Firewall
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Endpoint Security
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Advanced Malware and
                        Threat Protection
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Identity and Privacy
                        Protection
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Email and Attachments
                        protection
                      </li>
                      <li>
                        {" "}
                        <i className="fa fa-check"></i> Anti-virus programs
                      </li>
                    </ul>
                    <a className="link" href="/helpdesk">
                      Get help with cyber security
                    </a>
                  </p>
                </div>
                <div className="col-lg-5 col-lg-offset-2 col-sm-6">
                  <hr className="section-heading-spacer"></hr>
                  <div className="clearfix"></div>
                  <img className="img-responsive" src={cybersecurity} alt="" />
                </div>
              </div>
            </div>
          </div>

          <a name="contact"></a>
          <div className="banner">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2>Our Social Media</h2>
                </div>
                <div className="col-lg-6">
                  <ul className="list-inline banner-social-buttons">
                    <li>
                      <a
                        href="https://twitter.com/lamungatech"
                        className="btn btn-primary btn-lg"
                      >
                        <i className="fa fa-twitter fa-fw"></i>{" "}
                        <span className="network-name">Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/lamunga-technologies/"
                        className="btn btn-primary btn-lg"
                      >
                        <i className="fa fa-linkedin fa-fw"></i>{" "}
                        <span className="network-name">Linked In</span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://web.facebook.com/lamungatechnologies/"
                        className="btn btn-primary btn-lg"
                      >
                        <i className="fa fa-facebook fa-fw"></i>{" "}
                        <span className="network-name">Facebook</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <a name="clients"></a>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-5">
                <h2 className="section-heading">Contact us</h2>
                <address>
                  Lamunga Technologies Limited, 1st Floor, Bemwaki Towers, Irura
                  Road, Nanyuki, Laikipia Kenya. Email: sales@lamunga.com Web:
                  www.lamunga.com Phone: 0722654384
                </address>
                <div className="row">
                  <img className="map-section" src={lamungamap} />
                </div>
              </div>
              <div className="col-lg-6 col-lg-offset-0 col-sm-6">
                <div className="form-group">
                  <div className="clearfix"></div>
                  <div className="clearfix"></div>
                  <h3>
                    {" "}
                    <label className="arrow">View on Map</label>
                  </h3>
                  <div className="row">
                    <iframe className="map-section" src={map_key}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Home;
