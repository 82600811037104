import React from "react";

import Footer from "./Footer";
import Header from "./Header";

function Support() {
  return (
    <div classNameName="Support">
      <Header />

      <div>
        <div className="text-center banner intro-message">
          <h2>Helpdesk Subscription Plans</h2>
          <h4 className="container text-left">
            Get unlimited support for all your technology needs with our
            flexible plans. Subscribe today and put all your technology needs on
            check. Pay monthly, annually or as per agreement and get unlimited
            support.
          </h4>
          <br></br>
          <a className="btn btn-lg btn-danger" href="/getintouch">
            Start My Subscription
          </a>
        </div>
        <div className="row text-center">
          <h2 className="text-center">Personal Helpdesk Plans</h2>
          <hr></hr>
          <div className="col-lg-offset-1">
            <div className="col-lg-3 support-item" id="p-basic">
              <h2>Basic</h2>
              <div className="panel-body">
                <button className="btn btn-primary">Features</button>
                <hr></hr>
                <p>covers upto 3 selected services and applications</p>

                <p>Response within 3 working hours</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p className="p-3 mb-2 text-white bg-primary">
                  Ksh 1000 /user/month
                </p>
              </div>
            </div>
            <div className="col-lg-3 support-item" id="p-standard">
              <h2>Standard</h2>
              <div className="panel-body">
                <button className="btn btn-warning">Features</button>
                <hr></hr>
                <p>covers upto 5 selected services and applications</p>

                <p>Response within 1 working hour</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p>Basic Training included</p>

                <p>Best practises advisory</p>

                <p className="p-3 mb-2 text-white bg-warning">
                  Ksh 2,000/user/month
                </p>
              </div>
            </div>
            <div className="col-lg-3 support-item" id="p-premium">
              <h2>Premium</h2>
              <div className="panel-body">
                <button className="btn btn-danger">Features</button>
                <hr></hr>
                <p>Cover all technology services, applications and devices</p>

                <p>Response is immediate</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p>Basic Training included</p>

                <p>Best practises advisory</p>

                <p>Designated account manager</p>

                <p className="p-3 mb-2 text-white bg-danger">
                  Ksh 3,000/user/month
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row text-center">
          <h2 className="text-center">Business Helpdesk Plans</h2>
          <hr></hr>
          <div className="col-lg-offset-1 ">
            <div className="col-lg-3 support-item" id="b-basic">
              <h2>Basic</h2>
              <div className="panel-body">
                <button className="btn btn-primary">Features</button>
                <hr></hr>
                <p>
                  Support for upto 3 selected services, applications and devices
                </p>

                <p>Response within 3 working hours</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p>covers upto 5 users</p>

                <p className="p-3 mb-2 text-white bg-primary">
                  Ksh 5,000/business/month
                </p>
              </div>
            </div>
            <div className="col-lg-3 support-item" id="b-standard">
              <h2>Standard</h2>
              <div className="panel-body">
                <button className="btn btn-warning">Features</button>
                <hr></hr>
                <p>
                  Support for upto 5 selected services, applications and devices
                </p>

                <p>Response within 1 working hour</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p>covers upto 15 users</p>

                <p>Basic Training included</p>

                <p>Best practises advisory</p>

                <p className="p-3 mb-2 text-white bg-warning">
                  Ksh 10,000/business/month
                </p>
              </div>
            </div>
            <div className="col-lg-3 support-item" id="b-premium">
              <h2>Premium</h2>
              <div className="panel-body">
                <button className="btn btn-danger">Features</button>
                <hr></hr>
                <p>Covers any technology services, applications and devices</p>

                <p>Response is immediate within working hours or as agreed</p>

                <p>Online support</p>

                <p>24/7 Phone support</p>

                <p>covers upto 50 users</p>

                <p>Basic Training included</p>

                <p>Best practises advisory</p>

                <p>Designated account manager</p>

                <p>System health checks and notifications</p>

                <p className="p-3 mb-2 text-white bg-danger">
                  Ksh 20,000/business/month
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
      </div>

      <Footer />
    </div>
  );
}
export default Support;
