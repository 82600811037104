import React from "react";

import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      callMe: false,
      getQuote: false,
      name: "",
      email: "",
      phone: "",
      company_name: "",
      company_email: "",
      scope: "License implementation and support",
      budget: "Not clear yet",
      no_of_users: "",
      cloud_or_onpremise: "Cloud",
      details: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {}
  handleSubmit(event) {}

  componentDidMount() {
    axios.get("http://localhost:7000/products").then((response) => {
      console.log(response);
      this.setState({ data: response.data });
    });
  }
  callMe = () => {
    this.setState({ callMe: !this.state.callMe });
  };
  getQuote = () => {
    this.setState({ getQuote: !this.state.getQuote });
  };

  render() {
    if (this.state.callMe) {
      //call me form
      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>

          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Let us call you!</h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <form onSubmit={this.handleSubmit}>
                    Name:
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      value=""
                      onChange=""
                    />
                    Phone:
                    <input
                      className="form-control"
                      name="phone"
                      type="phone"
                      value=""
                      onChange=""
                    />
                    <br></br>
                    <input
                      className="btn-primary form-control"
                      type="submit"
                      value="Request Callback"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.getQuote) {
      //get quote form
      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>
          <div className="spacer"></div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Send Me a Quote!</h4>
              </div>
              <div className="modal-body">
                <form className="form-group" onSubmit={this.handleSubmit}>
                  Name:{" "}
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  Email:{" "}
                  <input
                    className="form-control"
                    name="email"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  Phone:{" "}
                  <input
                    className="form-control"
                    name="phone"
                    type="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                  Company Name:{" "}
                  <input
                    className="form-control"
                    name="company_name"
                    type="text"
                    value={this.state.company_name}
                    onChange={this.handleChange}
                  />
                  Company Email:{" "}
                  <input
                    className="form-control"
                    name="company_email"
                    type="email"
                    value={this.state.company_email}
                    onChange={this.handleChange}
                  />
                  No of users:{" "}
                  <select
                    className="form-control"
                    name="no_of_users"
                    type="number"
                    value={this.state.no_of_users}
                    onChange={this.handleChange}
                  >
                    <option>0-5 users</option>
                    <option>5-10 users</option>
                    <option>10-20 users</option>
                    <option>20-50 users</option>
                    <option>50 and above</option>
                  </select>
                  Scope:{" "}
                  <select
                    className="form-control"
                    name="scope"
                    type="text"
                    value={this.state.scope}
                    onChange={this.handleChange}
                  >
                    <option>License only</option>
                    <option>License and Implementation</option>
                    <option>License and Implementation and support</option>
                    <option>Support and maintenance</option>
                    <option>Training</option>
                    <option>Everything</option>
                  </select>
                  Cloud or On premise:{" "}
                  <select
                    className="form-control"
                    name="cloud_or_onpremise"
                    type="text"
                    value={this.state.cloud_or_onpremise}
                    onChange={this.handleChange}
                  >
                    <option>CLoud</option>
                    <option>On premise</option>
                    <option>Hybrid (both)</option>
                  </select>
                  Budget limit:{" "}
                  <select
                    className="form-control"
                    name="budget"
                    type="text"
                    value={this.state.cloud_or_onpremise}
                    onChange={this.handleChange}
                  >
                    <option>Not clear yet</option>
                    <option>0 - 20k</option>
                    <option> 20k- 70k</option>
                    <option> 70k - 150k</option>
                    <option>150k - 500k</option>
                    <option>500k and above</option>
                  </select>
                  Details:{" "}
                  <textarea
                    className="form-control"
                    name="details"
                    type="text"
                    value={this.state.details}
                    onChange={this.handleChange}
                  />
                  <br></br>
                  <input
                    className="btn-primary form-control"
                    type="submit"
                    value="Send Me a Quote"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    let products_data = [];
    this.state.data.forEach((res, i) => {
      products_data.push(
        //div here

        <div className="masonry-grid" key={res.id}>
          <div className="col-md-3 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img
                  src={res.imageurl}
                  classsName="mt-3 mx-auto img-fluid"
                  alt=""
                />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">{res.name}</a>
                </h3>
                <p>
                  <span className="price">From $ {res.price}</span>

                  {res.scope}
                  {res.billing}
                </p>
                <p>Commitment: {res.commitment}</p>
                <p className="shop-p">{res.description}</p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Call me
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        //div here
      );
    });
    return (
      <div>
        <Header />
        <div className="text-center banner intro-message">
          <h2>Products Range</h2>
          <h4 className="container text-left">
            We help your team accelerate business productivity with industry
            leading workplace tools for communication, collaboration,
            accounting, customer service, sales and resource management
          </h4>
        </div>
        <br></br>
        <div className="row col-lg-12">{products_data}</div>

        <Footer />
      </div>
    );
  }
}
export default Products;
