import React from "react";

import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import icon from "../assets/img/shop/thumbnail.png";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      subject: "General ICT Helpdesk and Support ",
      callMe: false,
      getQuote: false,
      name: "",
      email: "",
      phone: "",
      company_name: "",
      company_email: "",
      scope: "License implementation and support",
      budget: "Not clear yet",
      no_of_users: "",
      cloud_or_onpremise: "Cloud",
      details: "",
      id: 0,
      subjects: [
        { id: 1, name: "ICT Helpdesk and Support" },
        { id: 2, name: "Network Setup and Support" },
        { id: 3, name: "Cloud Migrations and Services" },
        { id: 4, name: "Application Development" },
        { id: 5, name: "Data Backup and Recovery" },
        { id: 6, name: "Business systems (ERP, CRM, POS, Email)" },
        { id: 7, name: "Website and Domain services" },
        { id: 8, name: "Computer repair and Maintenance" },
        { id: 9, name: "Payment Integration services" },
        { id: 10, name: "Business Process Automation" },
        { id: 11, name: "Data Analytics" },
        { id: 12, name: "IT Training" },
        { id: 13, name: "Server and Operating System Upgrades" },
        { id: 14, name: "IT Consulting" },
        { id: 15, name: "VPN, SSH, Remote Access Setup and support" },
        { id: 16, name: "Systems and Applications Management" },
        { id: 17, name: "CCTV Installation" },
      ],
    };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    axios.get("http://localhost:7000/").then((response) => {
      console.log(response);
      this.setState({ data: response.data });
    });
  }
  callMe = () => {
    this.setState({ callMe: !this.state.callMe });
  };
  getQuote = () => {
    this.setState({ getQuote: !this.state.getQuote });
  };

  render() {
    if (this.state.callMe) {
      //call me form
      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>

          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Let us connect with you</h4>
                <a className="" href="mailto:sales@lamunga.com">
                  <i className="fa fa-envelope" /> Sales@lamunga.com
                </a>
                <br></br>
                <a className="" href="tel:254722654384">
                  <i className="fa fa-phone fa-lg" /> +254722654384
                </a>
                <br></br>
                <a className="" href="https://wa.me/254722654384">
                  <i className="fa fa-comment pr-12" /> 254722654384
                </a>
                <br></br>
                <br></br>
                Support Subject: <a href="/services">Go to all subjects</a>
                <br></br>
                <div className="alert-success">
                  <select>
                    <option>Select subject</option>{" "}
                    <option>ICT Helpdesk and Support</option>{" "}
                    <option>Network Setup and Support</option>{" "}
                    <option>Cloud Migrations and Services</option>{" "}
                    <option>Application Development</option>{" "}
                    <option>Data Backup and Recovery</option>{" "}
                    <option>Business systems (ERP, CRM, POS, Email)</option>{" "}
                    <option>Website and Domain services</option>{" "}
                    <option>Computer repair and Maintenance</option>{" "}
                    <option>Payment Integration services</option>{" "}
                    <option>Business Process Automation</option>{" "}
                    <option>Data Analytics</option> <option>IT Training</option>{" "}
                    <option>Server and Operating System Upgrades</option>{" "}
                    <option>IT Consulting</option>{" "}
                    <option>VPN, SSH, Remote Access Setup and support</option>{" "}
                    <option>Systems and Applications Management</option>{" "}
                    <option>CCTV Installation</option> <option></option>{" "}
                    <option>Software Licensing</option> <option></option>{" "}
                  </select>
                </div>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <form onSubmit={this.handleSubmit}>
                    Name:
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      value={this.state.name}
                      onChange={this.changeHandler}
                    />
                    Phone:
                    <input
                      className="form-control"
                      name="phone"
                      type="text"
                      value={this.state.phone}
                      onChange={this.changeHandler}
                    />
                    <br></br>
                    Email:
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                    />
                    <br></br>
                    <input
                      className="btn-primary form-control"
                      type="submit"
                      value="Request a Call"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.getQuote) {
      //get quote form
      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>
          <div className="spacer"></div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Send Me a Quote!</h4>
              </div>
              <div className="modal-body">
                <form className="form-group" onSubmit={this.handleSubmit}>
                  Name:{" "}
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={this.changeHandler}
                  />
                  Email:{" "}
                  <input
                    className="form-control"
                    name="email"
                    type="text"
                    value={this.state.name}
                    onChange={this.changeHandler}
                  />
                  Phone:{" "}
                  <input
                    className="form-control"
                    name="phone"
                    type="phone"
                    value={this.state.phone}
                    onChange={this.changeHandler}
                  />
                  Company Name:{" "}
                  <input
                    className="form-control"
                    name="company_name"
                    type="text"
                    value={this.state.company_name}
                    onChange={this.changeHandler}
                  />
                  Company Email:{" "}
                  <input
                    className="form-control"
                    name="company_email"
                    type="email"
                    value={this.state.company_email}
                    onChange={this.changeHandler}
                  />
                  No of users:{" "}
                  <select
                    className="form-control"
                    name="no_of_users"
                    type="number"
                    value={this.state.no_of_users}
                    onChange={this.changeHandler}
                  >
                    <option>0-5 users</option>
                    <option>5-10 users</option>
                    <option>10-20 users</option>
                    <option>20-50 users</option>
                    <option>50 and above</option>
                  </select>
                  Scope:{" "}
                  <select
                    className="form-control"
                    name="scope"
                    type="text"
                    value={this.state.scope}
                    onChange={this.changeHandler}
                  >
                    <option>License only</option>
                    <option>License and Implementation</option>
                    <option>License and Implementation and support</option>
                    <option>Support and maintenance</option>
                    <option>Training</option>
                    <option>Everything</option>
                  </select>
                  Cloud or On premise:{" "}
                  <select
                    className="form-control"
                    name="cloud_or_onpremise"
                    type="text"
                    value={this.state.cloud_or_onpremise}
                    onChange={this.changeHandler}
                  >
                    <option>CLoud</option>
                    <option>On premise</option>
                    <option>Hybrid (both)</option>
                  </select>
                  Time lines:{" "}
                  <select
                    className="form-control"
                    name="budget"
                    type="text"
                    value={this.state.cloud_or_onpremise}
                    onChange={this.changeHandler}
                  >
                    <option>Immediate</option>
                    <option>In two weeks</option>
                    <option> In one month</option>
                    <option> In two months</option>
                    <option>Next calendar year</option>
                    <option>Next financial year</option>
                  </select>
                  Details:{" "}
                  <textarea
                    className="form-control"
                    name="details"
                    type="text"
                    value={this.state.details}
                    onChange={this.changeHandler}
                  />
                  <br></br>
                  <input
                    className="btn-primary form-control"
                    type="submit"
                    value="Send Me a Quote"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
    let products_data = [];
    this.state.data.forEach((res, i) => {
      products_data.push(
        //card

        //div here

        <div className="masonry-grid" key={res.id}>
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img
                  src={res.imageurl}
                  classsName="mt-3 mx-auto img-fluid"
                  alt=""
                />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">{res.name}</a>
                </h3>
                <p className="shop-p">{res.description}</p>
                <p>
                  <span className="">From Ksh {res.price}</span>

                  {res.scope}
                  {res.billing}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact sales
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        //div here
      );
    });
    return (
      <div>
        <Header />
        <div className="text-center banner intro-message">
          <h2>Services We Offer</h2>
          <h4 className="container text-left">
            We provide technology services to help your team accelerate business
            productivity with industry leading workplace tools for
            communication, collaboration, accounting, customer service, sales
            and resource management
          </h4>
          <br></br>
          <a className="btn btn-lg btn-danger" href="/getintouch">
            Submit a Service Request
          </a>
        </div>
        <br></br>

        <div className="masonry-grid" id="1">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">ICT Helpdesk and Support</a>
                </h3>
                <p className="shop-p">
                  Submit your technology issue to get help from our most
                  experienced technical team.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="2">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Network Setup and Support</a>
                </h3>
                <p className="shop-p">
                  maintains the network infrastructure, such as the routers and
                  switches, and troubleshoots network-related problems..{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a href="/getintouch">
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="3">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Cloud Migrations and Services</a>
                </h3>
                <p className="shop-p">
                  Get help creating the cloud architecture, choosing the
                  provider to meet your needs, cloud services budgeting and
                  estimations on workloads and data migration options.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="4">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Application Development</a>
                </h3>
                <p className="shop-p">
                  Let us build your custom application or module to add to your
                  existing technology stack.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="5">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Data Backup and Recovery</a>
                </h3>
                <p className="shop-p">
                  Get cutting edge data backup solutions both on premise and in
                  the cloud. We also help in recovering lost or corrupted data.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="6">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Business systems (ERP, CRM, POS, Email)</a>
                </h3>
                <p className="shop-p">
                  When your business needs an ERP a CRM or just a point of Sale
                  application or an accounting package. We have a solution for
                  every business need.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="7">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Website and Domain services</a>
                </h3>
                <p className="shop-p">
                  Let us build your website and manage your domain to give your
                  business an online presences it needs to attract new customers
                  and reatin existing customers as well.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="8">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Computer repair and Maintenance</a>
                </h3>
                <p className="shop-p">
                  Detect and remove computer viruses, update software, install
                  firewalls and security programs, upgrade computer memory, or
                  connect and configure Internet and network as well as repairs
                  when not functioning well.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="9">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Payment Integration services</a>
                </h3>
                <p className="shop-p">
                  We help your business Choose and Integrate Payment Gateway:
                  Online Payments, Transaction Processing, and Payment Gateways
                  Providers. Mpesa, Jenga API, Pesapal, Paypal and Stripe{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="10">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Business Process Automation</a>
                </h3>
                <p className="shop-p">
                  Get help automating your business processes to improve
                  productivity Reduced Time and Costs. Improved Quality.More
                  Efficient Task Management. Greater Customer Satisfaction.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="11">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Data Analytics</a>
                </h3>
                <p className="shop-p">
                  We help businesses optimize their performances, make better
                  business decisions and help analyze customer trends and
                  satisfaction, which can lead to new?and better?products and
                  services.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="12">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">IT Training</a>
                </h3>
                <p className="shop-p">
                  We provide training to help in boosting proper usage of
                  technology services. We also provide support after training if
                  employees have any questions at a later date.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="13">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Server and Operating System Upgrades</a>
                </h3>
                <p className="shop-p">
                  we help in Installing, configuring and maintain servers,
                  overseeing performance, backup, security and ensuring high
                  performance and efficiency.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="14">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">IT Consulting</a>
                </h3>
                <p className="shop-p">
                  Reach out if you have a technology need and need help finding
                  a solution. We have expertise from a wide range of industries.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="15">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">VPN, SSH, Remote Access Setup and support</a>
                </h3>
                <p className="shop-p">
                  Get help troubleshooting vpn connections, server acess with
                  SSH keys, remote connections and related issues with access to
                  websites, servers or remote content.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="masonry-grid" id="16">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">Systems and Applications Management</a>
                </h3>
                <p className="shop-p">
                  Maintaining the operating system of the servers and
                  applications on the server, such as the mail services and the
                  web server.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="masonry-grid" id="17">
          <div className="col-md-4 col-sm-12 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center image-container">
                <img src={""} classsName="mt-3 mx-auto img-fluid" alt="" />
                <a href="#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title">
                  <a href="#">CCTV Installation</a>
                </h3>
                <p className="shop-p">
                  Kepp your premises under surveillance with our CCTV
                  installation services.{" "}
                </p>

                <div className="elements-list">
                  <a onClick={this.callMe} title="call me">
                    <i className="fa fa-phone"></i> Contact Us
                  </a>
                  <a onClick={this.getQuote}>
                    <i className="fa pr-10"></i>Get help
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Services;
//<div className="row col-lg-12">{products_data}</div>;
