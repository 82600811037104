import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function Consult() {
  return (
    <div>
      <Header />

      <a name="consult"></a>
      <div className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>Expert Consultation Services Available.</h2>
              <h4>Consult for as low as Ksh.200 per session</h4>
            </div>
            <div className="col-lg-6">
              <ul className="list-inline banner-social-buttons">
                <li>
                  <a href="pesapal">
                    <button
                      type="button"
                      className="btn btn-primary btn-lg"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i className="fa fa-dollar fa-fw"></i>{" "}
                      <span className="network-name">Get Consultation</span>
                    </button>
                  </a>
                </li>
                <li>
                  <p>
                    <span className="network-name" />
                    Are you a professional?
                  </p>
                  <a className="link-p" href="signup">
                    Register now
                  </a>{" "}
                  <p>or</p>
                  <a href="login" className="link-p">
                    <a
                      className="network-name"
                      data-toggle="modal"
                      data-target="login"
                    />
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal col-lg-12"
        id="myModal"
        tabindex="1"
        role="dialog"
        aria-labelledby="myModalLabel"
      ></div>

      <Footer />
    </div>
  );
}
export default Consult;
