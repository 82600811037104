import React from "react";

import axios from "axios";
//import Pagination from "./pagination";
import Footer from "./Footer";
import Header from "./Header";
//images
import windows from "../assets/img/shop/windows10.png";
import kaspersky from "../assets/img/shop/kinternetsecurity.png";
import microsoft365 from "../assets/img/shop/microsoft365.png";
import office365 from "../assets/img/shop/office365.png";
import norton from "../assets/img/shop/nortondeluxe.png";
import navision from "../assets/img/shop/dynamicsnavstarter.png";
import dynamicscrm from "../assets/img/shop/dynamicscrm.png";
import project from "../assets/img/shop/project2019.png";
import visio from "../assets/img/shop/visio2019.png";
import visualstudio from "../assets/img/shop/visualstudio2019.png";
import quickbooks from "../assets/img/shop/qbpro2020.png";
import sage from "../assets/img/shop/sagequantum.png";
import retailman from "../assets/img/shop/retailman.jpg";
import accupos from "../assets/img/shop/accupos.jpg";
import azure from "../assets/img/shop/azure.png";
import aws from "../assets/img/shop/aws.jpg";
import googlecloud from "../assets/img/shop/gcp.png";
import freshbooks from "../assets/img/shop/freshbooks.png";
import zohobooks from "../assets/img/shop/zoho.png";
import gsuite from "../assets/img/shop/gsuite.png";
import salesforce from "../assets/img/shop/salesforce.png";
import dynamics365 from "../assets/img/shop/dynamics365.png";
import tallyerp from "../assets/img/shop/tally.png";

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    axios.get("http://localhost:7000/").then((response) => {
      console.log(response);
      this.setState({ data: response.data });
    });
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    let shop_data = [];
    this.state.data.forEach((res, i) => {
      shop_data.push(
        <div className="masonry-grid" key={res.id}>
          <div className="col-md-3 col-sm-6 masonry-grid-item">
            <div className="listing-item">
              <div className="text-center">
                <img
                  src={res.imageurl}
                  className="img-fluid overlay-container "
                  alt=""
                />
                <a href="!#" className="overlay small"></a>
              </div>
              <div className="listing-item-body clearfix">
                <h3 className="title shop-p">
                  <a href="#">{res.name}</a>
                </h3>

                <hr></hr>
                <p>{res.category}</p>

                <p className="shop-p">{res.description} </p>

                <span className="price">Ksh{res.price}</span>
                <div className="elements-list pull-right">
                  <a className="list-inline" href="#">
                    <i className="fa fa-shopping-cart pr-12" /> Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        //div end here
      );
    });

    return (
      <div>
        <Header />

        <br></br>
        <br></br>
        <br></br>
        <div className="row">
          <div className="sorting-filters">
            <form className="form-inline">
              <div className="form-group">
                <label>Arrange by</label>
                <select className="form-control">
                  <option selected="selected">Date</option>
                  <option>Price</option>
                  <option>Model</option>
                </select>
              </div>
              <div className="form-group">
                <label>Brand</label>
                <select className="form-control">
                  <option selected="selected">All</option>
                  <option>Microsoft</option>
                  <option>Google</option>
                  <option>AWS</option>
                  <option>karspersky</option>
                  <option>Lenovo</option>
                  <option>Hp</option>
                </select>
              </div>
              <div className="form-group">
                <label>Price $ (min/max)</label>
                <div className="row grid-space-10">
                  <div className="col-sm-6">
                    <input type="text" className="form-control"></input>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control col-xs-6"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Category</label>
                <select className="form-control">
                  <option selected="selected">All</option>
                  <option>Software</option>
                  <option>Hardware</option>
                  <option>Networking</option>
                  <option>Printers</option>
                  <option>Accessories</option>
                  <option>Security</option>
                  <option>Business</option>
                  <option>Cloud</option>
                </select>
              </div>
              <div className="form-group">
                <a href="#" className="btn btn-danger">
                  Go
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="filetr-panel">
            <div className="col-lg-2 text-left fixed-position">
              <div className="list-group list-inline">
                <div className="list-group">
                  <a
                    href="#"
                    className="list-group-item list-group-item-action disabled"
                  >
                    Categories
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    <i className="fa fa-angle-double-right"> </i>
                    Software
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    Networking
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    Security
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    Accounting
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    ERP
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    CRM
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    POS
                  </a>
                  <a
                    href="#"
                    className="list-group-item list-group-item-action"
                  >
                    {" "}
                    <i className="fa fa-angle-double-right"> </i>
                    Email
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={windows}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Windows 10</a>
                    </h3>

                    <hr></hr>
                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={kaspersky}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Kaspersky Antivirus</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={microsoft365}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Microsoft 365</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={office365}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Office 365</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={navision}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Dynamics Navision</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={dynamicscrm}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Dynamics CRM</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={project}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Microsot Project</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={visio}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Microsoft Visio</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={visualstudio}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Visual Studio</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={quickbooks}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Quickbooks</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={sage}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Sage Pastel</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={norton}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Norton Antivirus</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={retailman}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Retailman POS</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={accupos}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">AccuPOS</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={azure}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Microsoft Azure</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={aws}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Amazon Web Services</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={googlecloud}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Google Cloud</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={freshbooks}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Freshbooks</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={zohobooks}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Zoho books</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={gsuite}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">G-suite</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={salesforce}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Salesforce CRM</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={dynamics365}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Dynamics 365</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="masonry-grid">
              <div className="col-md-3 col-sm-6 masonry-grid-item">
                <div className="listing-item">
                  <div className="text-center">
                    <img
                      src={tallyerp}
                      className="img-fluid overlay-container "
                      alt=""
                    />
                    <a href="!#" className="overlay small"></a>
                  </div>
                  <div className="listing-item-body clearfix">
                    <h3 className="title shop-p">
                      <a href="#">Tally ERP</a>
                    </h3>

                    <hr></hr>
                    <p></p>

                    <p className="shop-p"></p>

                    <div className="elements-list pull-center">
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-comments pr-12" /> Get help
                      </a>
                      <a className="list-inline" href="/getintouch">
                        <i className="fa fa-shopping-cart pr-12" /> Order Online
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Shop;

//<div className="col-lg-10 pull-right">{shop_data}</div>
