import React from "react";

import Footer from "./Footer";
import Header from "./Header";

function Pesapal() {
  return (
    <div>
      <Header />

      <div class="clearfix">
        <br></br>
        <br></br>
        <br></br>
      </div>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="myModalLabel">
              Complete Payment
            </h4>
          </div>
          <div className="modal-body">
            <form action="pay/pesapal-iframe.php" method="post">
              <div className="table table-responsive">
                <div className="container col-lg-12">
                  First Name:
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail3"
                    name="first_name"
                    placeholder="first name"
                    required="required"
                  />
                  Last Name:
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    name="last_name"
                    placeholder="last name"
                    required="required"
                  />
                  Email:
                  <input
                    type="email"
                    className="form-control"
                    id="lname"
                    name="email"
                    placeholder="name@example.com"
                    required="required"
                  />
                  Type:
                  <input
                    type="text"
                    hidden
                    className="form-control"
                    name="type"
                    placeholder="MERCHANT"
                    value="MERCHANT"
                    required="required"
                  />
                  Amount:
                  <input
                    type="number"
                    hidden
                    className="form-control"
                    name="amount"
                    placeholder="Ksh.200.00"
                    required="required"
                  />
                  Reference:
                  <input
                    type="text"
                    hidden
                    className="form-control"
                    name="reference"
                    placeholder="CF000P"
                    value="CF000P"
                    required="required"
                  />
                  Description:
                  <textarea
                    rows="3"
                    className="form-control"
                    name="description"
                    placeholder="Brief Description..."
                    required="required"
                  ></textarea>
                  <hr></hr>
                  <input
                    type="submit"
                    className="form-control btn-primary"
                    value="Pay Now"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Pesapal;
