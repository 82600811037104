import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import {} from "react-bootstrap";

function Login() {
  return (
    <div>
      <Header />

      <div>
        <div class="clearfix">
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close btn-primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                Login
              </h4>
            </div>
            <div className="modal-body">
              <form action="pay/pesapal-iframe.php" method="post">
                <div className="table table-responsive">
                  <div className="container col-lg-12">
                    Email:
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      value=""
                    />
                    Password:{" "}
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="password"
                    />
                    <hr></hr>
                    <input
                      type="submit"
                      className="form-control btn-primary"
                      value="Login"
                    />
                    <p>
                      Don't have an account?{" "}
                      <a className="link" href="signup">
                        Register now
                      </a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Login;
