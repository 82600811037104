import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function Signup() {
  return (
    <div>
      <Header />

      <div class="clearfix">
        <br></br>
        <br></br>
        <br></br>
      </div>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="myModalLabel">
              Register as a professional
            </h4>
          </div>
          <div className="modal-body">
            <form action="pay/pesapal-iframe.php" method="post">
              <div className="table table-responsive">
                <div className="container col-lg-12">
                  <label>Full Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    name="full_name"
                    value=""
                  ></input>
                  Username:
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    placeholder="last name"
                    value=""
                  ></input>
                  Email:
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value=""
                  ></input>
                  Phone:
                  <input
                    type="text"
                    hidden
                    className="form-control"
                    name="phone"
                    value=""
                  ></input>
                  Profession:
                  <select name="profession" className="form-control">
                    <option value="">Web developer</option>
                    <option value="">Web Designer</option>
                    <option value="">Mobile Developer</option>
                    <option value="">IT Expert</option>
                  </select>
                  Availability:
                  <select name="availability" className="form-control">
                    <option value="">Available at daytime weekdays</option>
                    <option value="">Available at daytime weekends</option>
                    <option value="">Available at night weekdays</option>
                    <option value="">Available at night weekends</option>
                    <option value="">Always Available</option>
                  </select>
                  What you do exactly:
                  <textarea
                    rows="3"
                    className="form-control"
                    name="description"
                    placeholder="Brief Description..."
                  ></textarea>
                  <hr></hr>
                  <input type="checkbox" name="terms"></input>I have read and
                  agree to {""}
                  <a href="terms">terms</a>
                  <input
                    type="submit"
                    className="form-control btn-primary"
                    value="Register Account"
                  ></input>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Signup;
