import React from "react";

class ServiceCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      plan: [],
      company: [],
      amount: 0.0,
      start_date: "",
      end_date: "",
      classification: "business",
      details: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {}
  handleSubmit(event) {}

  render() {
    return (
      <div>
        <header />
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <label>
              Name:{" "}
              <input
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Email:{" "}
              <input
                name="email"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Phone:{" "}
              <input
                name="phone"
                type="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Start Date:{" "}
              <input
                name="start_date"
                type="date"
                value={this.state.start_date}
                onChange={this.handleChange}
              />
            </label>

            <label>
              Details:{" "}
              <textarea
                name="details"
                type="text"
                value={this.state.details}
                onChange={this.handleChange}
              />
            </label>

            <input type="submit" value="Submit" />
          </form>
          <footer />
        </div>
      </div>
    );
  }
}
export default ServiceCheckout;
