import React from "react";
import brand from "../assets/img/logo.png";

function Header() {
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg responsive-toolbar navbar-default  navbar-fixed-top topnav"
        role="navigation"
      >
        <div className="container topnav">
          <div className="navbar-header top-logo">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="w-nav-brand  brand-link" href="/">
              <img src={brand} alt="Lamunga Technologies" />
            </a>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto nav navbar-nav navbar-right ">
              <li className="nav-item">
                <a href="/" className="dropdown-toggle" data-toggle="dropdown">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="helpdesk">Helpdesk</a>
              </li>
              <li className="nav-item">
                <a href="services">Services</a>
              </li>
              <li className="nav-item">
                <a href="support">Pricing</a>
              </li>
              <li className="nav-item">
                <a href="about">About us</a>
              </li>
              <li className="nav-item">
                <a href="shop">Shop</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Header;
