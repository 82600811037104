import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: "Please fill in the fields above to get in touch with us",
      success:
        "Your message has been sent successfully. Our team will get back to you soon.",
      fail:
        " looks like something went wrong and your message could not be sent. Please email us on info@lamunga.com",
      showResponse: false,
      blank: "Please fill in all fields to send your message",
      alertClass: "alert alert-info",
      alertClassSuccess: "alert alert-success",
      alertClassDanger: "alert alert-danger",

      results: "",
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = this.state;
    if (name || email || message !== "") {
      axios
        .post(
          "https://prod-74.westeurope.logic.azure.com:443/workflows/380da98a81934a2c8c54308e1bef5b20/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2QztHDqZ1lP4bGsLDGyKfUd3fTRHYDhdiCiuk3m3Yz8",
          this.state,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.setState({
            dialog: this.state.success,
            alertClass: this.state.alertClassSuccess,
          });
        })
        .catch((err) => {
          this.setState({
            dialog: this.state.fail,
            alertClass: this.state.alertClassDanger,
          });
        });
    } else if (name || email || message === "") {
      this.setState({
        dialog: this.state.blank,
        alertClass: this.state.alertClassDanger,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
      });
    }
    this.setState({ name: "", email: "", phone: "", message: "" });
  };

  render() {
    const { name, email, phone, message } = this.state;
    let map_key =
      "https://www.google.com/maps/embed/v1/view?zoom=17&center=0.0074%2C37.0722&key=AIzaSyAdZUc6OLN8Nnj3_iLwKU7aGCNpKQj-3Hc";
    return (
      <div className="About-content">
        <Header />
        <a name="about"></a>
        <div className="content-section-b">
          <div className="text-center banner intro-message">
            <h2>About Us</h2>
            <h4 className="container text-left">
              An agile team of technicians ready to meet your technology needs
              on the go. We pride in the success of businesss and individuals
              that use technology to accelerate business productivity.
            </h4>
            <br></br>
            <a className="btn btn-lg btn-danger" href="/getintouch">
              Get in touch with us
            </a>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Vision</h2>
                </a>
                <p className="lead">
                  To accelerate business productivity through innovation and
                  modern computing technologies
                </p>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a>
                  <h2 className="section-heading">Profile</h2>
                </a>
                <p className="lead">
                  Reliable, Committed, Transparent, Fast, Industry leader, upto
                  date with emerging technologies
                </p>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Mission</h2>
                </a>
                <p className="lead">
                  To become a global leader in provision of IT services to
                  institutions, Businesses, Telecommunication companies, other
                  IT companies and Startups
                </p>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Core Values</h2>
                </a>
                <p className="lead">
                  Transparency, Speed, Responsibility, Performance, Timely
                  Delivery
                </p>
              </div>

              <div className="col-lg-4 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Methodology</h2>
                </a>
                <p className="lead">
                  we always stick to DevOps methodology. Continuously engaging
                  our clients with product prototypes.
                </p>
              </div>

              <div className="col-lg-4 col-sm-6">
                <a>
                  {" "}
                  <h2 className="section-heading">Awards</h2>
                </a>
                <p className="lead">
                  We are working around the clock to scoop awards. Keep checking
                  as awards will be coming soon
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-5">
                <h2 className="section-heading">Contact us</h2>
                <address>
                  Lamunga Technologies Limited, 1st Floor, Bemwaki Towers, Irura
                  Road, Nanyuki, Laikipia Kenya. Email: info@lamunga.com
                  Web:www.lamunga.com Phone: 0722654384
                </address>
                <div className="row">
                  <iframe className="map-section" src={map_key}></iframe>
                </div>
              </div>
              <div className="col-lg-6 col-lg-offset-0 col-sm-6">
                <div className="form-group">
                  <div className="clearfix"></div>
                  <form onSubmit={this.submitHandler.bind(this)}>
                    <div className="clearfix"></div>
                    <h3>
                      <label className="arrow">Send a Message</label>
                    </h3>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your name"
                      name="name"
                      required="required"
                      onChange={this.changeHandler}
                      value={name}
                    />
                    <br></br>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      name="email"
                      required="required"
                      value={email}
                      onChange={this.changeHandler}
                    />
                    <br />
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="(optional) enter your number"
                      name="phone"
                      value={phone}
                      onChange={this.changeHandler}
                    />
                    <br></br>
                    <textarea
                      className="form-control"
                      rows="4"
                      name="message"
                      required="required"
                      value={message}
                      onChange={this.changeHandler}
                    ></textarea>
                    <br></br>
                    <input
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                      value="Send"
                      onClick={this.submitHandler}
                    />
                  </form>
                  <br></br>
                  <div className={this.state.alertClass}>
                    <strong>{this.state.dialog}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default About;
