import React from "react";

import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

class Helpdesk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: "Please fill in the fields above to get in touch with us",
      success:
        "Your issue has been successfully submitted. One of our technicians will contact you shortly to resolve the issue.",
      fail:
        "Looks like something went wrong and your issue could not be submitted. Please email us on helpdesk@lamunga.com",
      showResponse: false,
      urlstore: "",
      alertClass: "alert alert-info",
      alertClassSuccess: "alert alert-success",
      alertClassDanger: "alert alert-danger",
      alertClassInfo: "alert alert-info",
      data: [],
      name: "",
      email: "",
      phone: "",
      issue: "",
      type: "Personal Helpdesk",
      b_type: "Helpdesk for Business",
      cName: "",
      cPhone: "",
      cDesignation: "",
      p_amount: "Ksh 2,000 for help upto 3 hours",
      b_amount: "Ksh 3,000 for help upto 3 hours",
      showForm: false,
      showFormBiz: false,
    };
    this.showForm = this.showForm.bind(this);
    this.showFormBiz = this.showFormBiz.bind(this);
  }
  componentDidMount() {
    axios.get("http://localhost:5000/services").then((response) => {
      console.log(response);
      this.setState({ data: response.data });
    });
  }
  showForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };
  showFormBiz = () => {
    this.setState({ showFormBiz: !this.state.showFormBiz });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    const { name, email, phone, issue } = this.state;
    e.preventDefault();
    if (name && email && issue !== "") {
      axios
        .post(
          "https://prod-80.westeurope.logic.azure.com:443/workflows/f30c98705cdd451f88827fa08c9b74bd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-uAD1gSbZqbvZ8aMCrnJyYtFgJRqBimnoUcj_JzCddU",
          this.state,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.setState({
            dialog: this.state.success,
            alertClass: this.state.alertClassSuccess,
          });
        })
        .catch((err) => {
          this.setState({
            dialog: this.state.fail,
            alertClass: this.state.alertClassDanger,
          });
        });
    } else if (name || email || issue === "") {
      this.setState({
        dialog: this.state.blank,
        alertClass: this.state.alertClassDanger,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        issue: this.state.issue,
      });
    }
    this.setState({
      type: this.type,
      p_amount: "",
      name: "",
      email: "",
      phone: "",
      issue: "",
    });
  };

  submitHandlerBiz = (e) => {
    const {
      name,
      email,
      phone,
      issue,
      cPhone,
      cName,
      cDesignation,
    } = this.state;
    e.preventDefault();
    if (name && email && issue && cName && cPhone && cDesignation !== "") {
      axios
        .post(
          "https://prod-117.westeurope.logic.azure.com:443/workflows/d453f170ed7a49faa5ef309e0d9849e7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5RI4haB-1YM2Q27hzK2kj5CnyoVMc7sCUYjFluHNz3k",
          this.state,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.setState({
            dialog: this.state.success,
            alertClass: this.state.alertClassSuccess,
          });
        })
        .catch((err) => {
          this.setState({
            dialog: this.state.fail,
            alertClass: this.state.alertClassDanger,
          });
        });
    } else if (
      name ||
      email ||
      phone ||
      issue ||
      cName ||
      cPhone ||
      cDesignation === ""
    ) {
      this.setState({
        dialog: this.state.blank,
        alertClass: this.state.alertClass,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        cName: this.state.cName,
        cPhone: this.state.cPhone,
        cDesignation: this.state.cDesignation,
        issue: this.state.issue,
      });
    }
    this.setState({
      type: this.state.type,
      b_amount: this.state.b_amount,
      name: "",
      email: "",
      phone: "",
      cName: "",
      cPhone: "",
      cDesignation: "",
      issue: "",
    });
  };

  render() {
    const { p_amount, name, email, phone, issue } = this.state;
    if (this.state.showForm) {
      let type = "Personal Helpdesk";
      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Get Helped Faster!</h4>
              </div>
              <div className="modal-body">
                <div className="alert alert-info">
                  <strong>Works in easy steps!</strong>
                  <ol>
                    <li>You Submit your issue below</li>
                    <li>Our help desk finds a solution before hand</li>
                    <li>You are contacted on email/phone with solution</li>
                    <li>
                      You pay the agreed amount on our m-pesa till number{" "}
                    </li>
                    <li>Solution is applied and issue is closed</li>
                    <li>
                      If solution does not work. You get a refund instantly.
                    </li>
                  </ol>
                  <a href="/support">
                    Check out our monthly subscription plans?
                  </a>
                </div>
                <br></br>

                <div className="form-group">
                  <form onSubmit={this.submitHandler}>
                    <input
                      className="form-control"
                      name="type"
                      type="text"
                      value="Personal Helpdesk"
                      onChange={this.changeHandler}
                      disabled
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="p_amount"
                      type="text"
                      value={p_amount}
                      onChange={this.changeHandler}
                      disabled
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      value={name}
                      placeholder="Your name"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={email}
                      placeholder="Your email"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="phone"
                      type="tel"
                      value={phone}
                      placeholder="(optional) enter phone number"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <textarea
                      className="form-control"
                      name="issue"
                      type="text"
                      value={issue}
                      placeholder="briefly describe your issues"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="btn-primary form-control"
                      type="submit"
                      value="Submit your issue"
                      onClick={this.submitHandler}
                    />
                    <br></br>
                    <div className={this.state.alertClass}>
                      <strong>{this.state.dialog}</strong>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.showFormBiz) {
      const {
        b_amount,
        name,
        email,
        phone,
        issue,
        cName,
        cPhone,
        cDesignation,
      } = this.state;
      let type = "Helpdesk for Business";

      return (
        <div>
          <Header />
          <br></br>
          <br></br>
          <br></br>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close btn-primary"
                  data-dismiss="modal"
                  aria-label="close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Get Help for the business!</h4>
              </div>
              <div className="modal-body">
                <div className="alert alert-info">
                  <strong>Works in easy steps!</strong>
                  <ol>
                    <li>Submit your business technology issue below</li>
                    <li>Our help desk finds a solution before hand</li>
                    <li>You are contacted on email/phone with quotation</li>
                    <li>Your business issues an LPO or PO </li>
                    <li>Solution is applied</li>
                    <li>Payment is completed.</li>
                    <li>Issue is closed</li>
                  </ol>
                  <a href="/support">
                    Check out our monthly subscription plans?
                  </a>
                </div>
                <br></br>

                <div className="form-group">
                  <form onSubmit={this.submitHandlerBiz}>
                    <input
                      className="form-control"
                      name="type"
                      type="text"
                      value="Helpdesk for Business"
                      onChange={this.changeHandler}
                      disabled
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="b_amount"
                      type="phone"
                      value={b_amount}
                      onChange={this.changeHandler}
                      disabled
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      value={name}
                      placeholder="Business or company name?"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      value={email}
                      placeholder="business email"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="cName"
                      type="text"
                      value={cName}
                      placeholder="contact person name"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>

                    <input
                      className="form-control"
                      name="cPhone"
                      type="phone"
                      value={cPhone}
                      placeholder="contact person phone"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>
                    <input
                      className="form-control"
                      name="cDesignation"
                      type="text"
                      value={cDesignation}
                      placeholder="contact person designation"
                      onChange={this.changeHandler}
                      required="required"
                    />
                    <br></br>

                    <textarea
                      className="form-control"
                      name="issue"
                      type="text"
                      value={issue}
                      placeholder="Briefly describe your business technical issues"
                      onChange={this.changeHandler}
                    />
                    <br></br>
                    <input
                      className="btn-primary form-control"
                      type="submit"
                      value="Submit your issue"
                      onClick={this.submitHandlerBiz}
                    />
                    <br></br>
                    <div className={this.state.alertClass}>
                      <strong>{this.state.dialog}</strong>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let services_data = [];
    this.state.data.forEach((res, i) => {
      services_data.push(
        //div here
        <div>
          <div className="col-lg-5 service-item box-style-2" key={res.id}>
            <div className="icon-container primary">
              <i className={res.iconUrl}></i>
            </div>
            <div className="body">
              <h4>{res.name}</h4>
              <p>{res.description}</p>
              <button onClick={this.showForm} className="btn btn-primary link">
                <span>Get Service</span>
              </button>
            </div>
          </div>
        </div>
        //div end
      );
    });

    return (
      <div>
        <Header />

        <div className="row">
          <div className="row">
            <div className=" banner intro-message">
              <div className="container text-center">
                <h2>ICT Help Desk for Everyone</h2>
                <h4 className="container text-left">
                  Submit your technology issue to get help from our most
                  experienced technical team. You will only be required to pay
                  once your issue has been resolved. Please add a brief
                  description to help our technical teams to quickly identify
                  the issue.
                </h4>
                <br></br>
                <a className=" btn btn-lg  btn-danger" href="/getintouch">
                  Get ICT Help Now
                </a>
              </div>
            </div>
            <br className="clearfix"></br>

            <br className="clearfix"></br>

            <div className="container">
              <div className="col-lg-5 support-item" id="p-on-demand">
                <h2>Personal -Helpdesk</h2>
                <div className="panel-body">
                  <p>
                    covers any technology services, applications and devices
                  </p>

                  <p>Response within 1 working hour</p>

                  <p>Online support</p>

                  <p>24/7 Phone Availability</p>

                  <p>Travel charges not included</p>

                  <p className="p-3 mb-2 text-white bg-success">
                    Ksh 2,000 for help upto 3 hours
                  </p>

                  <button
                    className="btn-lg  btn-danger"
                    onClick={this.showForm}
                  >
                    Get Personal Help Now
                  </button>
                </div>
              </div>
              <div className="col-lg-5 support-item" id="b-on-demand">
                <h2>Business - Helpdesk</h2>
                <div className="panel-body">
                  <p>
                    Covers any technology services, applications and devices
                  </p>

                  <p>Response within 1 working hour</p>

                  <p>Online support</p>

                  <p>24/7 Phone Availability</p>

                  <p>Travel charges not included for site visits</p>

                  <p className="p-3 mb-2 text-white bg-success">
                    Ksh 3,000 for help upto 3 hours
                  </p>

                  <button
                    className="btn-lg  btn-primary"
                    onClick={this.showFormBiz}
                  >
                    Get Business Help Now
                  </button>
                </div>
              </div>
            </div>

            <div className="row col-lg-offset-1">{services_data}</div>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Helpdesk;
