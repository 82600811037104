import React from "react";

class ShopCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company_name: "",
      company_email: "",
      scope: "Everything",
      budget: "Not clear yet",
      no_of_users: "",
      cloud_or_onpremise: "",
      details: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {}
  handleSubmit(event) {}

  render() {
    return (
      <div>
        <header />
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <label>
              Name:{" "}
              <input
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Email:{" "}
              <input
                name="email"
                type="text"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Phone:{" "}
              <input
                name="phone"
                type="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Company Name:{" "}
              <input
                name="company_name"
                type="text"
                value={this.state.company_name}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Company Email:{" "}
              <input
                name="company_email"
                type="email"
                value={this.state.company_email}
                onChange={this.handleChange}
              />
            </label>

            <label>
              No of users:{" "}
              <input
                name="no_of_users"
                type="number"
                value={this.state.no_of_users}
                onChange={this.handleChange}
              />
            </label>
            <label>
              Scope:{" "}
              <select
                name="scope"
                type="text"
                value={this.state.scope}
                onChange={this.handleChange}
              >
                <option>License only</option>
                <option>License and Implementation</option>
                <option>Support and maintenance</option>
                <option>Training</option>
                <option>Everything</option>
              </select>
            </label>
            <label>
              Cloud or On premise:{" "}
              <select
                name="cloud_or_onpremise"
                type="text"
                value={this.state.cloud_or_onpremise}
                onChange={this.handleChange}
              >
                <option>CLoud</option>
                <option>On premise</option>
                <option>Hybrid (both)</option>
              </select>
            </label>
            <label>
              Budget limit:{" "}
              <select
                name="budget"
                type="text"
                value={this.state.cloud_or_onpremise}
                onChange={this.handleChange}
              >
                <option>I dont know yet</option>
                <option>0 - 20k</option>
                <option> 20k- 70k</option>
                <option> 70k - 150k</option>
                <option>150k - 500k</option>
                <option>500k and above</option>
              </select>
            </label>

            <label>
              Details:{" "}
              <textarea
                name="details"
                type="text"
                value={this.state.details}
                onChange={this.handleChange}
              />
            </label>

            <input type="submit" value="Submit" />
          </form>
          <footer />
        </div>
      </div>
    );
  }
}
export default ShopCheckout;
