import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";

//Routes views
import Home from "./views/Home";
import Helpdesk from "./views/Helpdesk";
import Services from "./views/Services";
import Products from "./views/Products";
import About from "./views/About";
import Shop from "./views/Shop";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Consult from "./views/Consult";
import Pesapal from "./views/Pesapal";
import Support from "./views/Support";
import Getintouch from "./views/Getintouch";

//checkout route
import support_checkout from "./checkout/support-checkout";
import service_checkout from "./checkout/service-checkout";
import shop_checkout from "./checkout/shop-checkout";
import product_get_quote from "./checkout/product-get-quote";

//payment routes
import payment from "./payment/payment";

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />
      <Route exact path="/helpdesk" component={Helpdesk} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/about" component={About} />
      <Route exact path="/shop" component={Shop} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/consult" component={Consult} />
      <Route exact path="/pesapal" component={Pesapal} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/getintouch" component={Getintouch} />
      <Route exact path="/shop-checkout" component={shop_checkout} />
      <Route exact path="/service-checkout" component={service_checkout} />
      <Route exact path="/support-checkout" component={support_checkout} />
      <Route exact path="/product-get-quote" component={product_get_quote} />
      <Route exact path="/payment" component={payment} />
    </BrowserRouter>
  );
}

export default App;
