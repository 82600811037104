import React from "react";
//mobile icons

import microsft from "../assets/img/vendors/microsoft.png";
import citix from "../assets/img/vendors/citrix.png";
import ibm from "../assets/img/vendors/ibm.png";
import azure from "../assets/img/vendors/azure.png";
import kaspersky from "../assets/img/vendors/kaspersky.jpg";
import lenovo from "../assets/img/vendors/lenovo.png";
import oracle from "../assets/img/vendors/oracle.png";
import dell from "../assets/img/vendors/dell.jpg";
import office365 from "../assets/img/vendors/office365.png";
import microsoft365 from "../assets/img/vendors/microsoft365.jpg";
import sage from "../assets/img/vendors/sage.png";
import salesforce from "../assets/img/vendors/salesforce.png";
import riverbed from "../assets/img/vendors/riverbed.png";
import quickbooks from "../assets/img/vendors/quickbooks.png";
import gcp from "../assets/img/vendors/gcp.png";
import dynamics365 from "../assets/img/vendors/dynamics365.png";
import zoho from "../assets/img/vendors/zoho.png";

function Footer() {
  return (
    <div>
      <footer nav>
        <div className="content-section-b">
          <div className="col-lg-12" id="mobileapp">
            <h1 className="dark-bg text-center section-heading">
              Vendor Brands
            </h1>
            <ul className="list-inline">
              <li>
                <a href="">
                  <img className="img-responsive" src={microsft} />
                </a>
              </li>

              <li>
                <a href="">
                  <img className="img-responsive" src={kaspersky} />
                </a>
              </li>

              <li>
                <a href="">
                  <img className="img-responsive" src={ibm} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={citix} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={azure} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={lenovo} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={dell} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={oracle} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={office365} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={microsoft365} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={dynamics365} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={salesforce} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={quickbooks} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={sage} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={gcp} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={riverbed} />
                </a>
              </li>
              <li>
                <a href="">
                  <img className="img-responsive" src={zoho} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          <div className="col-lg-12">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>

              <li className="footer-menu-divider"></li>
              <li>
                <a href="/helpdesk">Helpdesk</a>
              </li>
              <li className="footer-menu-divider"></li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/support">Pricing</a>
              </li>

              <li>
                <a href="/about">About Us</a>
              </li>
              <li className="footer-menu-divider"></li>
              <li>
                <a href="/shop">Shop</a>
              </li>
              <li className="footer-menu-divider"></li>
            </ul>
            <p className="copyright text-muted small">
              &copy; <script>document.write(new Date().getFullYear())</script> |
              Lamunga Technologies | All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
