import React from "react";

import axios from "axios";
import Footer from "./Footer";
import Header from "./Header";
import { Route } from "react-router-dom";
import MultiSelect from "react-multi-select-component";

class Getintouch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: "Please complete the form below to get in touch with us",
      success:
        "Your message has been sent successfully. Our team will get back to you soon.",
      fail:
        " looks like something went wrong and your message could not be sent. Please email us on info@lamunga.com",
      showResponse: false,
      blank: "Please fill in all required fields then submit",
      alertClass: "alert alert-info",
      alertClassSuccess: "alert alert-success",
      alertClassDanger: "alert alert-danger",

      getInTouch: false,
      name: "",
      email: "",
      phone: "",
      product: "",
      interest: "Helpdesk",
      details: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    axios.get("http://localhost:5000/services").then((response) => {
      console.log(response);
      this.setState({ data: response.data });
    });
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    const { name, email, phone, product, interest, details } = this.state;
    e.preventDefault();
    if (name && email && product && interest && details !== "") {
      axios
        .post(
          "https://prod-172.westeurope.logic.azure.com:443/workflows/cfdf3cc636a54dc29b46188e6c122da5/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=zD-SvyahE1TQGlxeohlm885hkUeDLpkBgO8ukOzbSy8",
          this.state,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.setState({
            dialog: this.state.success,
            alertClass: this.state.alertClassSuccess,
          });
        })
        .catch((err) => {
          this.setState({
            dialog: this.state.fail,
            alertClass: this.state.alertClassDanger,
          });
        });
    } else if (name || email || product || interest || details === "") {
      this.setState({
        dialog: this.state.blank,
        alertClass: this.state.alertClassDanger,
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        product: this.state.product,
        interest: this.state.interest,
        details: this.state.details,
      });
    }
    this.setState({
      name: "",
      email: "",
      phone: "",
      product: "",
      interest: "",
      details: "",
      dialog: this.state.blank,
      alertClass: this.state.alertClassDanger,
    });
  };

  render() {
    const { name, email, phone, product, interest, details } = this.state;
    // const [selected, setSelected] = useState([]);
    return (
      <div>
        <Header />
        <br></br>
        <br></br>
        <br></br>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close btn-primary"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4>Get in touch with us</h4>
            </div>
            <div className="modal-body">
              <div className={this.state.alertClass}>
                <strong>{this.state.dialog}</strong>
              </div>
              <div className="form-group">
                <form onSubmit={this.submitHandler}>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={name}
                    onChange={this.changeHandler}
                    placeholder="enter your name"
                    required
                  />
                  <br></br>
                  <input
                    className="form-control"
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.changeHandler}
                    placeholder="enter your email"
                    required
                  />
                  <br></br>
                  <input
                    className="form-control"
                    name="phone"
                    type="tel"
                    pattern="[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}"
                    value={phone}
                    onChange={this.changeHandler}
                    placeholder="(optional) enter your phone "
                  />
                  <br></br>
                  <input
                    className="form-control"
                    name="product"
                    type="text"
                    value={product}
                    onChange={this.changeHandler}
                    placeholder="specify product, service or device e.g router, ERP, POS "
                    required
                  />
                  <br></br>
                  What are you interested with?
                  <select
                    className="form-control"
                    name="interest"
                    type="text"
                    value={interest}
                    onChange={this.changeHandler}
                    placeholder="enter your name"
                    required
                  >
                    <option>ICT Helpdesk and Support</option>
                    <option>Software License</option>
                    <option>Business Process Automation</option>
                    <option>Computer Devices and Accessories</option>
                    <option>Software Development</option>
                    <option>Wifi, LAN Network Setup and Support</option>
                    <option>Business Systems(ERP, CRM, POS)</option>
                    <option>Cloud Services</option>
                    <option>Website and Domain</option>
                    <option>Payments Integration</option>
                    <option>Data Analytics</option>
                    <option>IT Training</option>
                    <option>Cyber Security</option>
                    <option>Computer Repairs and maintenance</option>
                    <option>Helpdesk subscription Plans</option>
                    <option>other ICT topic</option>
                  </select>
                  <br></br>
                  <textarea
                    className="form-control"
                    name="details"
                    type="text"
                    placeholder="brief details about your interest or issue"
                    value={details}
                    onChange={this.changeHandler}
                    required
                  />
                  <br></br>
                  <input
                    className="btn-primary form-control"
                    type="submit"
                    value="Submit Request"
                    onClick={this.submitHandler}
                  />
                </form>
                <br></br>
                <div className={this.state.alertClass}>
                  <strong>{this.state.dialog}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Getintouch;
