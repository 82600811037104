import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "../views/Header";
import Footer from "../views/Footer";
//import SupportCheckout from "../checkout/support-checkout";

function Payment() {
  return (
    <div>
      <BrowserRouter>
        <Header />
      </BrowserRouter>
      <div>
        <br></br>
        <br></br>
        <br></br>
      </div>
      <BrowserRouter>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default Payment;
